<template>
  <div class="col main_attributes_wrapper_container">
    <!-- Team Statements List  -->
    <!-- //? Large Screens  -->
    <server-table
      class="show_in_large"
      :count="all_team_statements_count"
      :DataItems="all_team_statements"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :title="'Team Statements'"
      admin_permission="admin_tasks"
      edit_permission="add_tasks"
      @export="exportTeamStatements"
      import_url="statements/import"
    >
      <template #columns>
        <el-table-column
          prop="question_statement_title"
          sortable
          label="Title"
          min-width="120"
        ></el-table-column>

        <!-- statement Name  -->
        <el-table-column
          sortable
          prop="statement"
          label="statement"
          min-width="120"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.statement }}
            </div>
          </template></el-table-column
        >
        <el-table-column
          sortable
          prop="pattern"
          label="pattern"
          min-width="100"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.pattern }}
            </div>
          </template></el-table-column
        >
        <el-table-column
          sortable
          prop="classification"
          label="classification"
          min-width="100"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.classification }}
            </div>
          </template></el-table-column
        >
        <el-table-column prop="age_group" label="age group" min-width="100">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.age_group }}
            </div>
          </template></el-table-column
        >
        <el-table-column
          prop="Language"
          label="language"
          sortable
          min-width="100"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ language(lang, scope.row.language) }}
            </div>
          </template>
        </el-table-column>

        <!-- // Actions  -->
        <el-table-column prop="" label="_" min-width="100">
          <template slot-scope="scope">
            <div class="td_actions flex justify-content-end">
              <!-- Edit    -->
              <a
                v-tooltip.top-center="`Edit statement`"
                @click="open_statement_dialog(2, scope.row)"
                class="btn btn-warning btn-simple btn-link"
              >
                <i class="fa fa-edit"></i
              ></a>
              <!-- show   -->
              <a
                v-tooltip.top-center="`Show statement`"
                class="btn btn-info btn-link btn-xs"
                @click="show_every_statement_details(scope.row)"
              >
                <i class="fa fa-eye"></i>
              </a>
              <!-- Delete  -->
              <a
                @click="remove(scope.row.id)"
                v-tooltip.top-center="`Delete statement`"
                class="btn btn-danger btn-simple btn-link"
              >
                <i class="fa fa-times"></i
              ></a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #filter>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-select
              v-model="filter.language"
              placeholder=" Language"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in lang"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="filter.age_group"
              placeholder="Age Group"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(item, index) in ageGroup"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
      <!-- Add Task Button  -->
      <template #action>
        <div type="info" class="ml-2" @click="open_statement_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          Add Statement
        </div>
      </template>
    </server-table>
    <!-- // Start Medium Screens  -->
    <server-table
      class="show_in_medium"
      :count="all_team_statements_count"
      :DataItems="all_team_statements"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :title="'Team Statements'"
      @export="exportTeamStatements"
      import_url="statements/import"
      admin_permission="admin_tasks"
      edit_permission="add_tasks"
    >
      <template #columns>
        <el-table-column
          prop="question_statement_title"
          sortable
          label="Title"
          min-width="120"
        ></el-table-column>

        <el-table-column
          sortable
          prop="pattern"
          label="pattern"
          min-width="100"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.pattern }}
            </div>
          </template></el-table-column
        >
        <el-table-column
          sortable
          prop="classification"
          label="classification"
          min-width="100"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.classification }}
            </div>
          </template></el-table-column
        >
        <el-table-column prop="age_group" label="age group" min-width="100">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.age_group }}
            </div>
          </template></el-table-column
        >

        <!-- // Actions  -->
        <el-table-column prop="" label="_" min-width="100">
          <template slot-scope="scope">
            <div class="td_actions flex justify-content-end">
              <!-- Edit    -->
              <a
                v-tooltip.top-center="`Edit statement`"
                @click="open_statement_dialog(2, scope.row)"
                class="btn btn-warning btn-simple btn-link"
              >
                <i class="fa fa-edit"></i
              ></a>
              <!-- show   -->
              <a
                v-tooltip.top-center="`Show statement`"
                class="btn btn-info btn-link btn-xs"
                @click="show_every_statement_details(scope.row)"
              >
                <i class="fa fa-eye"></i>
              </a>
              <!-- Delete  -->
              <a
                @click="remove(scope.row.id)"
                v-tooltip.top-center="`Delete statement`"
                class="btn btn-danger btn-simple btn-link"
              >
                <i class="fa fa-times"></i
              ></a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #filter>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-select
              v-model="filter.language"
              placeholder=" Language"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in lang"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="filter.age_group"
              placeholder="Age Group"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(item, index) in ageGroup"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
      <!-- Add Task Button  -->
      <template #action>
        <div type="info" class="ml-2" @click="open_statement_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          Add Statement
        </div>
      </template>
    </server-table>
    <!-- // End Medium Screens  -->

    <!-- // Start Small Screens  -->
    <server-table
      class="show_in_small"
      :count="all_team_statements_count"
      :DataItems="all_team_statements"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :title="'Team Statements'"
      @export="exportTeamStatements"
      import_url="statements/import"
      admin_permission="admin_tasks"
      edit_permission="add_tasks"
    >
      <template #columns>
        <el-table-column
          prop="question_statement_title"
          sortable
          label="Title"
          min-width="120"
        ></el-table-column>

        <!-- // Actions  -->
        <el-table-column prop="" label="_" min-width="100">
          <template slot-scope="scope">
            <div class="td_actions flex justify-content-end">
              <!-- Edit    -->
              <a
                v-tooltip.top-center="`Edit statement`"
                @click="open_statement_dialog(2, scope.row)"
                class="btn btn-warning btn-simple btn-link"
              >
                <i class="fa fa-edit"></i
              ></a>
              <!-- show   -->
              <a
                v-tooltip.top-center="`Show statement`"
                class="btn btn-info btn-link btn-xs"
                @click="show_every_statement_details(scope.row)"
              >
                <i class="fa fa-eye"></i>
              </a>
              <!-- Delete  -->
              <a
                @click="remove(scope.row.id)"
                v-tooltip.top-center="`Delete statement`"
                class="btn btn-danger btn-simple btn-link"
              >
                <i class="fa fa-times"></i
              ></a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #filter>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-select
              v-model="filter.language"
              placeholder=" Language"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in lang"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="filter.age_group"
              placeholder="Age Group"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(item, index) in ageGroup"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
      <!-- Add Task Button  -->
      <template #action>
        <div type="info" class="ml-2" @click="open_statement_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          Add Statement
        </div>
        <div type="info" class="ml-2" @click="open_statement_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          Add Statement
        </div>
      </template>
    </server-table>
    <!-- // End Small Screens  -->

    <!-- // Start Add or Edit Statement  -->
    <el-dialog
      :title="type == 1 ? `Add Statement` : `Edit Statement`"
      width="60%"
      :visible.sync="add_statement_dialog"
      top="4vh"
      class="add_attributes_dialog_wrapper"
    >
      <el-form
        :model="statement"
        style="width: 100%"
        ref="addAttributeForm"
        :rules="addAttributeRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24"
            ><el-form-item
              label="Statement"
              style="width: 100%"
              prop="statement"
            >
              <el-input
                type="text"
                v-model="statement.statement"
                placeholder="Please enter title"
                style="width: 100%"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="24">
            <el-form-item label="Language" style="width: 100%" prop="language">
              <el-select
                v-model="statement.language"
                placeholder="Please select a language"
                style="width: 100%"
              >
                <el-option
                  v-for="(language, i) in lang"
                  :key="i"
                  :label="language.title"
                  :value="language.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24"
            ><el-form-item
              label="Title"
              prop="question_id"
              class="w-100 mr-lg-3"
            >
              <el-select
                v-model="statement.question_id"
                placeholder="Please select a type"
                style="width: 100%"
              >
                <el-option
                  v-for="(statementLanguage, i) in statementsDependsOnLanguage"
                  :key="i"
                  :label="statementLanguage.title"
                  :value="statementLanguage.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24"
            ><el-form-item
              label="Patterns"
              prop="pattern"
              class="w-100 mr-lg-3"
            >
              <el-select
                v-model="statement.pattern"
                placeholder="Please select a type"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, i) in patterns"
                  :key="i"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24"
            ><el-form-item
              label="Classification"
              prop="classification"
              class="w-100 mr-lg-3"
            >
              <el-select
                v-model="statement.classification"
                placeholder="Please select a Classification"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, i) in statementsTypes"
                  :key="i"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              :label="`${localization('Age Group')}`"
              prop="age_group"
            >
              <el-select
                v-model="statement.age_group"
                :placeholder="`${localization('Please select Age group')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in ageGroup"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="add_statement_dialog = false"
          >Cancel</el-button
        >
        <el-button
          class="text-center text-capitalize save_button"
          type=""
          @click="save"
          >Save Statement</el-button
        >
      </span>
    </el-dialog>
    <!-- // End Add or Edit Statement  -->

    <!-- // Start Show Team Statement  -->
    <el-dialog
      title="Statement Details"
      width="60%"
      :visible.sync="show_statement_details"
      top="4vh"
      class="add_attributes_dialog_wrapper show_statement_details"
      :close-on-click-modal="false"
    >
      <div v-if="every_statement_details">
        <div class="pick_state">
          <h4 class="pick_title">Title</h4>
          <p class="pick_text">
            {{ every_statement_details.question_statement_title }}
          </p>
        </div>
        <div class="pick_state">
          <h4 class="pick_title">Statement</h4>
          <p class="pick_text">{{ every_statement_details.statement }}</p>
        </div>
        <div class="pick_state">
          <h4 class="pick_title">Pattern</h4>
          <p class="pick_text">{{ every_statement_details.pattern }}</p>
        </div>
        <div class="pick_state">
          <h4 class="pick_title">Age Group</h4>
          <p class="pick_text">{{ every_statement_details.age_group }}</p>
        </div>
        <div class="pick_state">
          <h4 class="pick_title">Language</h4>
          <p class="pick_text">
            {{ language(lang, every_statement_details.language) }}
          </p>
        </div>
      </div>
    </el-dialog>
    <!-- // End Show Team Statement  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
export default {
  // name: "index",
  components: { ServerTable },
  data() {
    return {
      show_statement_details: false,
      every_statement_details: {},
      add_statement_dialog: false,
      patterns: ["Sequence", "Precision", "Technical Reasoning", "Confluence"],
      statement: {
        id: null,
        statement: null,
        language: null,
        question_id: null,
        pattern: null,
        classification: null,
        age_group: null
      },
      type: null,
      filter: {},
      languages: [],
      some_statements: [],
      // for filter

      addAttributeRules: {
        statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        question_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        pattern: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        classification: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        age_group: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      statementsTypes: ["Avoid", "As Needed", "Use First"],
      ageGroup: [{ label: "Adult" }, { label: "Child" }, { label: "Youth" }]
    };
  },
  computed: {
    all_team_statements() {
      return this.$store.getters["attributes/get_team_statements"];
    },
    all_team_statements_count() {
      return this.$store.getters["attributes/get_team_statements_count"];
    },
    lang() {
      return this.$store.getters["attributes/languages"];
    },
    statementsDependsOnLanguage() {
      let stateLang = [];
      let teamStatement = this.$store.getters[
        "attributes/getQuestionStatementsFilter"
      ];
      teamStatement.forEach(element => {
        if (element.language == this.statement.language) {
          stateLang.push({
            title: element.question,
            id: element.id
          });
        }
      });
      return stateLang;
    }
  },
  mounted() {
    this.Refresh();
    // console.log(this.$store.getters["attributes/languages"]);
  },
  methods: {
    Refresh(query) {
      this.$store
        .dispatch("attributes/GetTeamStatements", { query: query })
        .then(_ => {
          this.languages = this.$store.getters["attributes/languages"];
        });
    },
    // format language for table show
    language(languages, id) {
      let language = languages.filter(el => el.id == id)[0];
      if (language) {
        return language.title;
      }
      return "-";
    },

    open_statement_dialog(type, value) {
      // statement: null,
      //   language: null,
      //   question_id: null

      this.statement.id = value.id;
      this.statement.statement = value.statement;
      this.statement.language = value.language;
      this.statement.question_id = value.question_id;
      this.statement.pattern = value.pattern;
      this.statement.classification = value.classification;
      this.statement.age_group = value.age_group;
      this.add_statement_dialog = true;
      this.type = type;
    },
    remove(id) {
      console.log(id);
      Swal.fire({
        title: "",
        text: `Are you sure to delete this Statement?`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Delete",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("attributes/DeleteTeamStatement", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: "",
              text: "Statement has been deleted.",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          });
        }
      });
    },

    save() {
      this.$refs["addAttributeForm"].validate(valid => {
        if (valid) {
          // add new attribute
          if (this.type == 1) {
            this.$store
              .dispatch("attributes/AddTeamStatement", {
                query: this.statement
              })
              .then(_ => {
                this.Refresh({});
                this.add_statement_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("attributes/EditTeamStatement", {
                id: this.statement.id,
                query: this.statement
              })
              .then(_ => {
                this.Refresh({});
                this.add_statement_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          }
        } else {
          return false;
        }
      });
    },
    show_every_statement_details(obj) {
      this.show_statement_details = true;
      this.every_statement_details = obj;
    },
    exportTeamStatements(query) {
      this.$store.dispatch("user/handlerExportTeamStatements", {
        query: query
      });
    }
  }
};
</script>

<style scoped lang="scss">
// ::v-deep .el-dialog__close {
//   display: block !important;
//   cursor: pointer;
// }
.show_in_large {
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
}
.show_in_medium {
  display: none;
  @media (min-width: 700px) and (max-width: 1200px) {
    display: block;
  }
}
.show_in_small {
  display: none;
  @media (min-width: 320px) and (max-width: 700px) {
    display: block;
  }
}
.container--fluid {
  min-height: 80vh !important;
}

::v-deep .el-table .cell {
  white-space: normal;
  word-break: break-word;
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    background-color: #23ccef;
    border-color: #23ccef;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
.show_statement_details {
  .pick_state {
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    // border-bottom: 1px solid #ccc;
    color: #777;
    .pick_title {
      margin: 0 0 10px;
    }
    .pick_text {
      margin: 0;
    }
  }
}
</style>

<style lang="scss">
.main_attributes_wrapper_container {
  .add_attributes_dialog_wrapper {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
.show_statement_details {
  .el-dialog__close {
    display: block !important;
    cursor: pointer;
  }
}
</style>
